/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import {
  Box,
  Menu as ChakraMenu,
  MenuIcon,
  useColorModeValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/react';
import type {
  CoercedMenuPlacement,
  GroupBase,
  GroupHeadingProps,
  GroupProps,
  MenuListProps,
  MenuProps,
  NoticeProps,
  OptionProps,
} from 'react-select';

// App - Types
import type { SizeProps, ThemeObject } from '../types/types';

// App - Other
import { CheckIcon } from '@/components/atoms/icon';
import { cleanCommonProps } from '../utils/clean-common-props';
import { useSize } from '../hooks/use-size';

const alignToControl = (placement: CoercedMenuPlacement) => {
  const placementToCSSProp = { bottom: 'top', top: 'bottom' };

  return placement ? placementToCSSProp[placement] : 'top';
};

export const Menu = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MenuProps<Option, IsMulti, Group>
) => {
  const {
    className,
    cx,
    children,
    innerProps,
    innerRef,
    placement,
    selectProps: { chakraStyles },
  } = props;

  const initialSx: SystemStyleObject = {
    position: 'absolute',
    [alignToControl(placement)]: '100%',
    marginY: '8px',
    width: '100%',
    zIndex: 1,
  };

  const sx = chakraStyles?.menu ? chakraStyles.menu(initialSx, props) : initialSx;

  return (
    <ChakraMenu>
      <Box
        {...innerProps}
        ref={innerRef}
        className={cx({ menu: true }, className, 'select-menu')}
        sx={sx}
      >
        {children}
      </Box>
    </ChakraMenu>
  );
};

export const MenuList = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MenuListProps<Option, IsMulti, Group>
) => {
  const {
    className,
    cx,
    innerRef,
    children,
    maxHeight,
    isMulti,
    innerProps,
    selectProps: { chakraStyles, size: sizeProp, variant, focusBorderColor, errorBorderColor },
  } = props;

  const menuStyles = useMultiStyleConfig('Menu');

  // We're pulling in the border radius from the theme for the input component
  // so we can match the menu lists' border radius to it, but in 2.8.0 the value
  // was changed to being pulled from a theme variable instead of being hardcoded
  const size = useSize(sizeProp);
  const inputStyles = useMultiStyleConfig('Input', {
    size,
    variant,
    focusBorderColor,
    errorBorderColor,
  });
  const fieldStyles = inputStyles.field as Record<string, string>;

  const initialSx: SystemStyleObject = {
    ...menuStyles.list,
    minW: '100%',
    maxHeight: `${maxHeight}px`,
    overflowY: 'auto',
    // This is hacky, but it works. May be removed in the future
    '--input-border-radius': fieldStyles?.['--input-border-radius'],
    borderRadius: fieldStyles?.borderRadius || menuStyles.list?.borderRadius,
    position: 'relative', // required for offset[Height, Top] > keyboard scroll
    WebkitOverflowScrolling: 'touch',
  };

  const sx = chakraStyles?.menuList ? chakraStyles.menuList(initialSx, props) : initialSx;

  return (
    <Box
      role="listbox"
      {...innerProps}
      className={cx(
        {
          'menu-list': true,
          'menu-list--is-multi': isMulti,
        },
        className,
        'select-menu-list'
      )}
      sx={sx}
      ref={innerRef}
    >
      {children}
    </Box>
  );
};

export const LoadingMessage = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: NoticeProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    selectProps: { chakraStyles, size: sizeProp },
  } = props;

  const size = useSize(sizeProp);

  const verticalPaddings: SizeProps = {
    sm: '6px',
    md: '8px',
    lg: '10px',
  };

  const initialSx: SystemStyleObject = {
    color: 'chakra-subtle-text',
    textAlign: 'center',
    paddingY: verticalPaddings[size],
    fontSize: size,
  };

  const sx = chakraStyles?.loadingMessage
    ? chakraStyles.loadingMessage(initialSx, props)
    : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          'menu-notice': true,
          'menu-notice--loading': true,
        },
        className,
        'select-loading-message'
      )}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export const NoOptionsMessage = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: NoticeProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    selectProps: { chakraStyles, size: sizeProp },
  } = props;

  const size = useSize(sizeProp);

  const verticalPaddings: SizeProps = {
    sm: '6px',
    md: '8px',
    lg: '10px',
  };

  const initialSx: SystemStyleObject = {
    color: 'chakra-subtle-text',
    textAlign: 'center',
    py: verticalPaddings[size],
    fontSize: size,
  };

  const sx = chakraStyles?.noOptionsMessage
    ? chakraStyles.noOptionsMessage(initialSx, props)
    : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          'menu-notice': true,
          'menu-notice--no-options': true,
        },
        className,
        'select-no-options-message'
      )}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export const Group = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: GroupProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    theme,
    getStyles,
    Heading,
    headingProps,
    label,
    selectProps,
    innerProps,
    getClassNames,
  } = props;

  const { chakraStyles } = selectProps;

  const initialSx: SystemStyleObject = {};
  const sx = chakraStyles?.group ? chakraStyles.group(initialSx, props) : initialSx;

  return (
    <Box {...innerProps} className={cx({ group: true }, className, 'select-group')} sx={sx}>
      <Heading
        {...headingProps}
        selectProps={selectProps}
        cx={cx}
        theme={theme}
        getStyles={getStyles}
        getClassNames={getClassNames}
      >
        {label}
      </Heading>

      <Box>{children}</Box>
    </Box>
  );
};

export const GroupHeading = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: GroupHeadingProps<Option, IsMulti, Group>
) => {
  const {
    cx,
    className,
    selectProps: { chakraStyles, size: sizeProp, hasStickyGroupHeaders },
  } = props;

  const { data, ...innerProps } = cleanCommonProps(props);

  const menuStyles = useMultiStyleConfig('Menu');

  const size = useSize(sizeProp);

  const fontSizes: SizeProps = {
    sm: 'xs',
    md: 'sm',
    lg: 'md',
  };

  const paddings: SizeProps = {
    sm: '6px 12px',
    md: '8px 16px',
    lg: '10px 20px',
  };

  const initialSx: SystemStyleObject = {
    ...menuStyles.groupTitle,
    fontSize: fontSizes[size],
    padding: paddings[size],
    margin: 0,
    borderBottomWidth: hasStickyGroupHeaders ? '1px' : 0,
    position: hasStickyGroupHeaders ? 'sticky' : 'static',
    top: -2,
    bg: menuStyles.list.bg,
    zIndex: 1,
  };

  const sx = chakraStyles?.groupHeading ? chakraStyles.groupHeading(initialSx, props) : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx({ 'group-heading': true }, className, 'select-group-heading')}
      sx={sx}
    />
  );
};

export const Option = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: OptionProps<Option, IsMulti, Group>
) => {
  const {
    className,
    cx,
    innerRef,
    innerProps,
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: {
      chakraStyles,
      size: sizeProp,
      isMulti,
      hideSelectedOptions,
      selectedOptionStyle,
      selectedOptionColor,
    },
  } = props;

  const menuItemStyles: ThemeObject = useMultiStyleConfig('Menu').item;

  const size = useSize(sizeProp);
  const horizontalPaddingOptions: SizeProps = {
    sm: '10px',
    md: '13px',
    lg: '16px',
  };
  const verticalPaddingOptions: SizeProps = {
    sm: '4px',
    md: '6px',
    lg: '8px',
  };

  const selectedColor = useColorModeValue('white', 'black');

  // Don't create exta space for the checkmark if using a multi select with
  // options that dissapear when they're selected
  const showCheckIcon =
    selectedOptionStyle === 'check' && (!isMulti || hideSelectedOptions === false);

  const shouldHighlight = selectedOptionStyle === 'color';

  const initialSx: SystemStyleObject = {
    ...menuItemStyles,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'start',
    fontSize: size,
    px: horizontalPaddingOptions[size],
    py: verticalPaddingOptions[size],
    ...(shouldHighlight && {
      _selected: {
        bg: selectedOptionColor,
        color: selectedColor,
        _active: { bg: selectedOptionColor },
      },
    }),
  };

  const sx = chakraStyles?.option ? chakraStyles.option(initialSx, props) : initialSx;

  return (
    <Box
      role="option"
      {...innerProps}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
        'select-option'
      )}
      sx={sx}
      ref={innerRef}
      data-focus={isFocused ? true : undefined}
      aria-disabled={isDisabled ? true : undefined}
      aria-selected={isSelected}
    >
      {showCheckIcon && (
        <MenuIcon me="12px" opacity={isSelected ? 1 : 0}>
          <CheckIcon size="xs" aria-label="Check option" />
        </MenuIcon>
      )}

      {children}
    </Box>
  );
};
