export const REVIEWS_ROUTES = {
  root: '/reviews',

  reviews: () => `${REVIEWS_ROUTES.root}`,

  review: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}`,
  reviewOverview: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=overview`,
  reviewAnalysis: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=analysis`,
  reviewThreats: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=threats`,
  reviewThreat: (reviewId: string, threatId: string) =>
    `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=threats&threat=${threatId}`,
  reviewRequirements: (reviewId: string) =>
    `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=requirements`,
  reviewRequirement: (reviewId: string, requirementId: string) =>
    `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=requirements&requirement=${requirementId}`,

  reviewCandidates: () => `${REVIEWS_ROUTES.reviews()}?view=review-candidate-details`,
  reviewCandidate: (candidateId: string) =>
    `${REVIEWS_ROUTES.reviews()}?view=review-candidate-details&id=${candidateId}`,
};
